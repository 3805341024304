<template>
    <div>
        <plugin-preloader :brand_loading_hex_color="brand_loading_hex_color"></plugin-preloader>
        <section :class="full_wrapper_classes">
            <!-- Banner Section -->
            <section class="home-banner">
                <img class="banner-image object-fit_cover"
                     :src="url_prepend + bannerImage" />
                <div class="banner-text"
                     v-show="brand !== '4'">Get a free quote</div>
            </section>
            <!-- /Banner Section -->
            <section class="section-content">
                <div class="clearfix">
                    <div class="region-content">
                        <div class="region-content-inner">
                            <div class="search_steps">
                                <!-- <h1 class="text-center">Get a Free Quote</h1> -->
                                <p class="header_text text-center"
                                   v-if="brand !== '4'">
                                    Elevating your home or property with award-winning
                                    {{ brandName }}® products is just a few clicks away. Please
                                    answer the questions below to get started.
                                </p>
                                <QuoteStepForm :service_types="this.service_types"
                                               :home_types="this.home_types"
                                               :new_buy_plans="this.new_buy_plans"
                                               :window_amount="this.window_amount"
                                               :door_amount="this.door_amount"
                                               :window_importance="this.window_importance"
                                               :interested_products="this.interested_products"
                                               :lead_types="this.lead_types"
                                               :business_types="this.business_types"
                                               :project_conditions="this.project_conditions"
                                               :project_types="this.project_types"
                                               :states="this.states"
                                               :brand="this.brand"
                                               :source_id="1"
                                               :brand_ga="this.brand_ga"
                                               :interested_financing="this.interested_financing"
                                               :redirect_thankyou="this.redirect_thankyou"
                                               :countries="this.countries"
                                               :default_country="this.default_country"
                                               :data_form="this.data_form"
                                               :frontend_url="this.frontend_url"
                                               :client_ip="this.client_ip"
                                               :user_agent="this.user_agent"
                                               :homes_built_per_year="this.homes_built_per_year"
                                               :brand_object="this.brand_info">
                                </QuoteStepForm>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <dealer-locator :brand="this.brand"
                            :brand-object="this.brand_info"
                            :brandName="this.brandName"
                            :brand_ga="this.brand_ga"
                            :business_types="this.business_types"
                            :client_ip="this.client_ip"
                            :countries="this.countries"
                            :data_form="this.data_form"
                            :default_country="this.default_country"
                            :door_amount="this.door_amount"
                            :frontend_url="this.frontend_url"
                            :full_wrapper_classes="this.full_wrapper_classes"
                            :home_types="this.home_types"
                            :homes_built_per_year="this.homes_built_per_year"
                            :iframe_domain="this.iframe_domain"
                            :interested_financing="this.interested_financing"
                            :interested_products="this.interested_products"
                            :lead_types="this.lead_types"
                            :new_buy_plans="this.new_buy_plans"
                            :project_conditions="this.project_conditions"
                            :project_types="this.project_types"
                            :redirect_thankyou="this.redirect_thankyou"
                            :service_types="this.service_types"
                            :states="this.states"
                            :user_agent="this.user_agent"
                            :window_amount="this.window_amount"
                            :window_importance="this.window_importance"
                            :brand_subdomain="this.brand_subdomain"
                            :retailers_enabled="retailers_enabled"></dealer-locator>

            <section class="circle-wrapper">
                <div class="section-circle">
                    <div class="circle-table-cell circle-cont-left">
                        <h3>Excellence Through Partnership</h3>
                    </div>
                    <div class="circle-table-cell circle-cont-right">
                        Each year, we honor exceptional dealers who have showcased the highest dedication to serving their
                        communities with products that are built for the everyday and the once-in-a-lifetime. This program,
                        Excellence Through Partnership, is divided into three tiers: Gold, Platinum, and Diamond.
                    </div>
                </div>
                <div class="grid-custom">
                    <div class="grid-custom-grid3">
                        <div class="form-type-select">
                            <select class="form-select circle-select-option">
                                <option>Diamond Dealers List</option>
                                <option value="WEATHER TITE WINDOWS">WEATHER TITE WINDOWS</option>
                                <option value="GLASS PROFESSIONALS">GLASS PROFESSIONALS</option>
                                <option value="RAYMOND BUILDING SUPPLY LLC">
                                    RAYMOND BUILDING SUPPLY LLC
                                </option>
                                <option value="FLORIDA WINDOWS & DOORS">
                                    FLORIDA WINDOWS & DOORS
                                </option>
                                <option value="SOUTHERN EXPOSURE SUNROOMS">
                                    SOUTHERN EXPOSURE SUNROOMS
                                </option>
                                <option value="ALL GLASS">ALL GLASS</option>
                                <option value="DIRECT WINDOW & DOOR">DIRECT WINDOW & DOOR</option>
                                <option value="RICE WINDOWS & DOORS">RICE WINDOWS & DOORS</option>
                                <option value="HBS, INC">HBS, INC</option>
                                <option value="R Q BUILDING PRODUCTS INC">R Q BUILDING PRODUCTS INC</option>
                                <option value="SUNCOAST CONTRACTORS SUPPLY">SUNCOAST CONTRACTORS SUPPLY</option>
                                <option value="CARROLLWOOD WINDOW & DOOR, INC">CARROLLWOOD WINDOW & DOOR, INC</option>
                                <option value="PRESTIGE WINDOWS & DOORS">PRESTIGE WINDOWS & DOORS</option>
                                <option value="DIAMOND GLASS & ALUMINUM, LLC">DIAMOND GLASS & ALUMINUM, LLC</option>
                                <option value="ENGLEWOOD GLASS & MIRROR & AUTO">ENGLEWOOD GLASS & MIRROR & AUTO</option>
                                <option value="DIAMOND GLASS & ALUMINUM, LLC">DIAMOND GLASS & ALUMINUM, LLC</option>

                            </select>
                        </div>
                    </div>
                    <div class="grid-custom-grid3">
                        <div class="form-type-select">
                            <select class="form-select circle-select-option">
                                <option>Platinum Dealers List</option>
                                <option value="SUN WINDOW & DOOR">SUN WINDOW & DOOR</option>
                                <option value="DOVI WINDOW DESIGNS, INC">DOVI WINDOW DESIGNS, INC</option>
                                <option value="ALLIED BUILDING PRODUCTS">ALLIED BUILDING PRODUCTS</option>
                                <option value="CROW INDUSTRIES">CROW INDUSTRIES</option>
                                <option value="M AND W WINDOWS, LLC">M AND W WINDOWS, LLC</option>
                                <option value="BAY GLASS & WINDOW  INC">BAY GLASS & WINDOW  INC</option>
                                <option value="TRIPLE DIAMOND GLASS PRODUCTS">TRIPLE DIAMOND GLASS PRODUCTS</option>
                                <option value="ACTIVE DOOR & WINDOW CO INC">ACTIVE DOOR & WINDOW CO INC</option>
                                <option value="E & D QUALITY INSTALL, INC">E & D QUALITY INSTALL, INC</option>
                                <option value="GUARDIAN HURRICANE PROTECTION">GUARDIAN HURRICANE PROTECTION</option>
                                <option value="KIMAL LUMBER COMPANY">KIMAL LUMBER COMPANY</option>
                                <option value="STEVE'S WINDOWS AND DOORS">STEVE'S WINDOWS AND DOORS</option>
                                <option value="WEST COAST WINDOW & DOOR, INC">WEST COAST WINDOW & DOOR, INC</option>
                                <option value="LOUMAC DISTRIBUTORS-US LBM LLC">LOUMAC DISTRIBUTORS-US LBM LLC</option>
                            </select>
                        </div>
                    </div>
                    <div class="grid-custom-grid3">
                        <div class="form-type-select">
                            <select class="form-select circle-select-option">
                                <option>Gold Dealers List</option>
                                <option value="FLORIDA WOOD WINDOW & DOOR">FLORIDA WOOD WINDOW & DOOR</option>
                                <option value="SHUTTER UP INDUSTRIES">SHUTTER UP INDUSTRIES</option>
                                <option value="SOLAR-TITE, INC">SOLAR-TITE, INC</option>
                                <option value="VERO GLASS & MIRROR">VERO GLASS & MIRROR</option>
                                <option value="CLEARWATER WINDOW & DOOR">CLEARWATER WINDOW & DOOR</option>
                                <option value="PREMIER GLASS & SCREEN, INC">PREMIER GLASS & SCREEN, INC</option>
                                <option value="MANNING BUILDING SUPPLIES">MANNING BUILDING SUPPLIES</option>
                                <option value="JVR IMPACT WINDOWS & DOORS">JVR IMPACT WINDOWS & DOORS</option>
                                <option value="FLORIDA SOLAR & AIR">FLORIDA SOLAR & AIR</option>
                                <option value="TRENDEX CORPORATION">TRENDEX CORPORATION</option>
                                <option value="O'DONNELL IMPACT WINDOWS & STORM PROTECTION">O'DONNELL IMPACT WINDOWS & STORM
                                    PROTECTION</option>
                                <option value="SARASOTA GLASS & MIRROR, INC">SARASOTA GLASS & MIRROR, INC</option>
                                <option value="DALY ALUMINUM">DALY ALUMINUM</option>
                                <option value="BUDD SEVERINO ADV HOME EXT">BUDD SEVERINO ADV HOME EXT</option>
                                <option value="SOUTH FLORIDA WINDOWS & DOORS">SOUTH FLORIDA WINDOWS & DOORS</option>
                                <option value="W & W LUMBER COMPANY">W & W LUMBER COMPANY</option>
                                <option value="WEATHERMASTERS">WEATHERMASTERS</option>
                                <option value="GRAYHAWK SYSTEMS, LLC">GRAYHAWK SYSTEMS, LLC</option>
                                <option value="EAST COAST LUMBER & SUPPLY CO">EAST COAST LUMBER & SUPPLY CO</option>
                                <option value="TMD WINDOWS & DOORS">TMD WINDOWS & DOORS</option>
                                <option value="BAHAMA GLASS & WINDOW  INC">BAHAMA GLASS & WINDOW  INC</option>
                                <option value="NAPLES GLASS & ALUMINUM INC">NAPLES GLASS & ALUMINUM INC</option>
                            </select>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<style>
.windoorGold-form-checkbox {
    padding: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1.65rem !important;
    width: 1.65rem !important;
    color: #B9975B;
    border-style: solid;
    border-width: 1px;
    background-color: #fff;
    border-radius: 0 !important;
    margin-top: 0;
    cursor: pointer;
}

.windoorGold-form-checkbox:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.windoorGold-form-checkbox:checked+span {
    color: #B9975B;
}

.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

#nextSlideArrow svg path,
#prevSlideArrow svg path {
    color: #B9975B;
}
</style>

<style scoped>
.wtb-wrapper .finance_badge {
    width: 120px;
    margin-top: 10px;
}

.wtb-wrapper .form_section {
    min-height: 0;
}

.wtb-wrapper .step_form_main {
    padding: 25px 32px 65px;
}

.wtb-wrapper .step_form_main.step_two {
    padding-top: 30px;
}

.wtb-wrapper .button_cont .custom_btn {
    width: 10rem;
}

.wtb-wrapper .dealer_weburl_approved a {
    display: block;
}

.wtb-wrapper .button_cont button {
    font-weight: 400;
    width: 100%;
    border-width: 0;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #f6b221;
    color: #003865;
    height: 57px;
    font-size: 21px;
    font-family: inherit;
}

.wtb-wrapper .button_cont button.prev_btn {
    float: left;
    font-weight: 300;
    background-color: #003865;
    color: #f6b221;
}

.wtb-wrapper .dealer_etp_status button,
.wtb-wrapper .dealer_etp_status a {
    width: 165px !important;
}

.wtb-wrapper .dealer_etp_status a {
    padding-top: 10px;
}

.wtb-wrapper .dealer-locator-results {
    float: left;
}

@media only screen and (max-width: 991px) {
    .wtb-wrapper .finance_badge {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}
</style>

<style lang="scss">
.block-enter {
    opacity: 0;
}

.block-enter-active {
    animation: slide-in 0.5s ease-out forwards;
    transition: opacity 1s;
}

.block-leave {
    opacity: 0;
}

.block-leave-active {
    animation: slide-out 0s ease-out forwards;
    transition: opacity 0s;
    opacity: 0;
}

@keyframes slide-in {
    from {
        transform: translateY(20px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-20px);
    }
}
</style>

<script>
import * as VueGoogleMaps from "vue2-google-maps";
//import VueScrollTo from "vue-scrollto";
let VueScrollTo = require("vue-scrollto");
import SvgLocation from './SvgLocation.vue';
import SvgPhone from './SvgPhone.vue';
import DealerLocator from "./DealerLocator";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GMAP_API_KEY,
        libraries: "places",
        components: "country:us",
    },
    installComponents: true,
});

import GoogleMap from "./GoogleMap";
import QuoteStepForm from "./QuoteStepForm.vue";

export default {
    name: "finddealer",
    data () {
        return {
            dealers: {},
            mapSelector: "https",
            show_results: "none",
            show_distance: 0,
            search_status: 0,
            bannerImage: "assets/images/banner1.png",
            brandName: "PGT",
            data_form: "shared",
            params: {
                zipcode: "",
                distance: 15,
                latitude: 0.0,
                longitude: 0.0,
                type: 1,
                showroom: false,
                primary_segment: ["R"],
                brand: this.brand,
                international: false,
            },
            distances: this.distanceOptions(),
            has_errors: false,
            domain: process.env.MIX_APP_DOMAIN,
            userLocation: "" /* this.getUserZipCode() */,
            showUseMyLocationButton: false,
            no_results: false,
            innerWidth: null,
            maxHeight: 900,
            page: 1,
            perPage: 3,
            pages: [],
        };
    },
    props: {
        append_classes: "",
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        window_importance: {},
        door_amount: {},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        coi_dealers: {},
        states: {},
        brand: "",
        brand_info: {},
        brand_ga: "",
        iframe_domain: "",
        interested_financing: "",
        redirect_thankyou: "",
        countries: {},
        default_country: "",
        retailers_enabled: "",
        frontend_url:
            "" /** For images and enpoints. Not needed unless accessing from other domain */,
        client_ip: "",
        user_agent: "",
        brand_subdomain: "",
        homes_built_per_year: "",
        brand_loading_hex_color: "",
    },
    components: {
        GoogleMap,
        QuoteStepForm,
        SvgLocation,
        SvgPhone,
        DealerLocator
    },
    created () {
        if (this.brand == 2) {
            this.bannerImage = "assets/images/banner_cgi.jpg";
            this.brandName = "CGI";
        }
        if (this.brand == 3) {
            this.bannerImage = "assets/images/banner_windoor.jpg";
            this.brandName = "WinDoor";
        }
        if (this.brand == 4) {
            this.bannerImage = "assets/images/banner_eze.jpg";
            this.brandName = "Eze Breeze";
        }
    },
    mounted () {
        //this.initApiToken();
        //this.geoLocationRequest();
        if (
            navigator.platform.indexOf("iPhone") != -1 ||
            navigator.platform.indexOf("iPad") != -1
        ) {
            this.mapSelector = "maps";
        }


        this.innerWidth = window.innerWidth;
        window.addEventListener('resize', () => {
            this.innerWidth = window.innerWidth
        })
    },
    methods: {
        sanitizeZipCode () {
            this.params.zipcode = this.params.zipcode.replace(/[A-z]/g, "");
        },
        submitSearch () {
            if (this.show_results === 'loading') return;
            if (this.search_status === 0) {
                if (this.params.international) {
                    this.searchDealers(1);
                } else {
                    this.fetchLatLong();
                }
            } else if (this.search_status === 1) {
                this.searchDealers(1);
            }
        },
        getUserZipCode () {
            if (!google) {
                return;
            }
            var _t = this;
            navigator.geolocation.getCurrentPosition((position) => {
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;
                var point = new google.maps.LatLng(lat, lng);
                new google.maps.Geocoder().geocode(
                    {
                        latLng: point,
                    },
                    function (res, status) {
                        if (status === "OK") {
                            var address = res[0].formatted_address;
                            var zip = address.match(/,\s\w{2}\s(\d{5})/);
                            if (zip && zip[1]) {
                                _t.params.zipcode = zip[1];
                            }
                        }
                    }
                );
            });
        },
        hideUseMyLocationButton () {
            setTimeout(() => {
                this.showUseMyLocationButton = false;
            }, 200);
        },
        useMyLocation () {
            this.getUserZipCode();
        },
        fetchLatLong () {
            if (!this.params.zipcode) {
                this.has_errors = true;
                return true;
            }

            this.show_results = "loading";

            window.axios.defaults.headers.common = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };

            var map_url =
                "https://maps.googleapis.com/maps/api/geocode/json?key=" +
                process.env.MIX_GMAP_API_KEY +
                "&components=postal_code:" +
                this.params.zipcode;
            axios.get(map_url).then(
                (response) => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0) {
                            var location = response.data.results[0].geometry.location;
                            this.params.latitude = location.lat;
                            this.params.longitude = location.lng;
                            this.searchDealers(1);
                        } else {
                            this.no_results = true;
                            this.show_results = "none";
                            this.dealers = [];
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        searchDealers (search_type) {
            this.params.type = search_type;
            this.page = 1;
            axios.post(this.url_prepend + "/api/dealer/search", this.params).then(
                (response) => {
                    this.scrollTo('#result-container');
                    if (response.data.success && response.data.dealers && response.data.dealers.length) {
                        this.dealers = response.data.dealers;
                        this.show_results = "yes";
                        this.show_distance = search_type == 1 ? 1 : 0;
                        this.no_results = false;
                    } else {
                        this.no_results = false;
                        this.show_results = "none";
                        this.dealers = [];
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        distanceOptions () {
            var distance = [
                { id: 15, value: "15 Miles" },
                { id: 20, value: "20 Miles" },
                { id: 25, value: "25 Miles" },
                { id: 30, value: "30 Miles" },
                { id: 50, value: "50 Miles" },
                { id: 100, value: "100 Miles" },
            ];

            return distance;
        },
        showQuotePopup (dealer) {
            this.$modal.show(
                QuoteStepForm,
                {
                    dealer: dealer,
                    brand: this.brand,
                    brand_ga: this.brand_ga,
                    domain: this.domain,
                    service_types: this.service_types,
                    home_types: this.home_types,
                    new_buy_plans: this.new_buy_plans,
                    window_amount: this.window_amount,
                    door_amount: this.door_amount,
                    window_importance: this.window_importance,
                    interested_products: this.interested_products,
                    lead_types: this.lead_types,
                    business_types: this.business_types,
                    project_conditions: this.project_conditions,
                    project_types: this.project_types,
                    states: this.states,
                    show_close: true,
                    interested_financing: this.interested_financing,
                    redirect_thankyou: this.redirect_thankyou,
                    countries: this.countries,
                    default_country: this.default_country,
                    data_form: "unique",
                    frontend_url: this.frontend_url,
                    add_wrapper: true,
                    homes_built_per_year: this.homes_built_per_year,
                    brand_object: this.brand_info,
                },
                {
                    height: "auto",
                    clickToClose: false,
                    name: "quote_modal",
                }
            );

            setTimeout(() => {
                parent.postMessage({ task: "scroll_top" }, "*");
                setTimeout(window.pgtAccomodatePopup, 300);
            }, 50);
        },
        showBusinessPopup () {
            this.$modal.show(BusinessContact);
        },
        initApiToken () {
            axios
                .post(this.url_prepend + "/api/token/request", {
                    dealer_id: "general",
                    secret: "general",
                })
                .then(
                    (response) => {
                        if (response.data.success) {
                            console.log(response.data.message);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        geoLocationRequest () {
            // var $this = this;
            // var geoSuccess = function(position) {
            //     $this.params.latitude = position.coords.latitude;
            //     $this.params.longitude = position.coords.longitude;
            //     $this.searchDealers(0);
            // };
            // var geoError = function(error) {
            //     console.log(
            //         "Error occurred. Error code: " +
            //             error.code +
            //             ", Error message: " +
            //             error.message
            //     );
            // };
            //navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
        },
        scrollTo (elementId) {
            const element = document.querySelector(elementId);
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        },
        setPages () {
            let numberOfPages = Math.ceil(this.dealers.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        paginate (dealers) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return dealers.slice(from, to);
        },
    },
    computed: {
        full_wrapper_classes () {
            let out = 'wtb-wrapper';
            if (this.append_classes) {
                out += ' ' + this.append_classes;
            }
            return out;
        },
        platinum () {
            return _.filter(this.coi_dealers, { level: 3 });
        },
        diamond () {
            return _.filter(this.coi_dealers, { level: 2 });
        },
        gold () {
            return _.filter(this.coi_dealers, { level: 1 });
        },
        brand_subdomain_prefix () {
            return this.brand_subdomain ? this.brand_subdomain : "";
        },
        url_prepend () {
            return this.frontend_url ? this.frontend_url : "";
        },
        brandPrimaryColor () {
            let color = 'ezeprimary';
            switch (this.brand) {
                case 2:
                    color = 'cgi';
                    break;
                case 3:
                    color = 'windoorGold';
                    break;
                case 4:
                    color = 'ezeprimary'
                    break;
                case 6:
                    color = 'ecoGreen'
                    break;
                default:
                    color = 'pgt';
            }

            return color;
        },
        brandSecondaryColor () {
            let color = 'pgtGray';
            switch (this.brand) {
                case 2: // CGI
                    color = 'cgi-dark-grey';
                    break;
                case 3:
                    color = 'windoor';
                    break;
                case 4:
                    color = 'eze'
                    break;
                case 6:
                    color = 'ecoDark'
                    break;
                default:
                    color = 'pgtGray';
            }

            return color;
        },
        brandPrimaryFontFamily () {
            let fontFamily = 'campton';
            switch (this.brand) {
                case 2:
                    fontFamily = 'adellesans';
                    break;
                case 3:
                    fontFamily = 'gibson';
                    break;
                case 4:
                    fontFamily = 'opensans'
                    break;
                case 6:
                    fontFamily = 'ptsans'
                    break;
                default:
                    fontFamily = 'campton';
            }

            return fontFamily;
        },

        displayedDealers () {
            return this.paginate(this.dealers);
        },
    },
    watch: {
        "params.international": function (val) {
            if (val) {
                this.params.primary_segment = [];
            } else {
                this.params.primary_segment = ["R"];
            }
        },
        dealers (newValue, oldValue) {
            if (this.maxHeight < 1024) {
                this.setPages();
            }

            let vm = this;
            this.$nextTick(() => {
                if (vm.maxHeight >= 1024) {
                    let nodes = document.querySelectorAll("div[data-name='desktopDealer']");
                    let iterations = 0;
                    let dealersToShow = 5;
                    let maxHeight = 0;
                    nodes.forEach(function (node) {
                        if (iterations === dealersToShow) return;
                        iterations += 1;

                        maxHeight += node.clientHeight;
                    })

                    vm.maxHeight = maxHeight;
                }
            })
        }
    },
};
</script>
