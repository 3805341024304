<template>
  <div
    :class="
      shownOnModal
        ? 'w-full md:px-6 md:py-10 ez-form-wrapper wtb-wrapper ' +
          full_wrapper_classes
        : 'xl:max-w-5xl w-full relative lg:px-12 md:px-10 md:py-8 ez-form-wrapper ' +
          full_wrapper_classes
    "
    :style="
      shownOnModal
        ? 'background-color: rgba(' + brandRgbaTuple + ', 1)'
        : 'background-color: rgba(' + brandRgbaTuple + ', 0.90)'
    "
    class="p-6"
    data-name="martin-quote-form"
  >
    <!-- Loader -->
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <!-- End Loader -->
    <div class="form-container-ez">
      <button
        v-if="show_close"
        class="absolute text-white top-0 right-0 p-4 bg-transparent border-none"
        @click="$modal.hide('quote_modal')"
      >
        &times;
      </button>
      <h3
        class="text-center block lg:hidden text-white font-bold text-base mb-8 form-subtitle"
      >
        The beauty and safety of a Martin garage door is just a few clicks away.
        Please fill out this form to get started.
      </h3>

      <!-- Begin Questions -->
      <div
        class="form-questions-step-1 grid grid-cols-3 gap-4 grid-flow-row justify-items-stretch"
      >
        <!-- What best describes you? -->
        <div class="col-span-3 lg:col-span-1" data-name="whatBestDescribesYou">
          <div class="flex flex-col text-sm text-white">
            <h4
              :class="shownOnModal ? 'mr-4' : 'mr-8'"
              class="question-label mb-2"
            >
              What best describes you?
            </h4>
            <div class="block">
              <div v-for="(answer, index) in lead_types" :key="index" class="mb-2 md:mb-0">
                <label
                  :class="shownOnModal ? 'mr-2' : 'mr-8 md:mr-10'"
                  class="inline-flex items-baseline"
                  ><input
                    :id="`myGarageIs_${answer.name}`"
                    v-model="quote_request.leadType"
                    :value="answer.id"
                    autocomplete="off"
                    class="eze-form-radio"
                    name="garage"
                    type="radio"
                  />
                  <span class="text-sm form-answer ml-1"> {{ answer.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.leadType != '',
            }"
          >
            {{ this.errors.leadType }}
          </div>
        </div>

        <!-- New Or Existing Project-->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">
            What type of project is this?
          </h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.serviceType != '',
            }"
          >
            {{ errors.serviceType }}
          </div>
          <div
            v-for="(answer, index) in service_types"
            :key="answer.name"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`typeOfProject_${answer.name}`"
                v-model="quote_request.serviceType"
                :value="answer.id"
                autocomplete="off"
                class="eze-form-radio"
                name="panels"
                type="radio"
              />
              <span class="ml-1 form-answer">{{ answer.name }}</span>
            </label>
          </div>
        </div>

        <!-- How many garage doors? -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">How many garage doors?</h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.doorAmount != '',
            }"
          >
            {{ this.errors.doorAmount }}
          </div>
          <div
            v-for="(question, index) in this.door_amount"
            :key="`${index}_${question.name}`"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`doorAmount_${question.name}`"
                v-model="quote_request.doorAmount"
                :value="question.id"
                autocomplete="off"
                class="eze-form-radio"
                name="doorAmount"
                type="radio"
              />
              <span class="ml-1 form-answer">{{ question.name }}</span>
            </label>
          </div>
        </div>

        <!-- Are they single or double doors-->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">
            Are they single or double doors?
          </h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.doorType != '',
            }"
          >
            {{ errors.doorType }}
          </div>
          <div
            v-for="(answer, index) in questions.doorTypes"
            :key="answer.answer"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`typeOfDoors_${answer.answer}`"
                v-model="quote_request.doorType"
                :value="answer.value"
                autocomplete="off"
                class="eze-form-radio"
                name="doorType"
                type="radio"
              />
              <span class="ml-1 form-answer">{{ answer.answer }}</span>
            </label>
          </div>
        </div>

        <!-- Will you need a new garage door opener along with your new door(s)? -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">
            Will you need a new garage door opener along with your new door(s)?
          </h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.needDoorOpener != '',
            }"
          >
            {{ this.errors.needDoorOpener }}
          </div>
          <div
            v-for="(question, index) in questions.needDoorOpener"
            :key="index"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`needDoorOpener_${question.answer}`"
                v-model="quote_request.needDoorOpener"
                :value="question.value"
                autocomplete="off"
                class="eze-form-radio"
                name="door_opener"
                type="radio"
              />
              <span class="ml-1 form-answer">{{ question.answer }}</span>
            </label>
          </div>
        </div>

        <!-- Do you have a material preference? -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">
            Do you have a material preference?
          </h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.materialPreference != '',
            }"
          >
            {{ this.errors.materialPreference }}
          </div>

          <div
            v-for="(answer, index) in questions.materialPreference"
            :key="answer.answer"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`materialPreference_${answer.name}`"
                :value="answer.value"
                v-model="quote_request.framePreference"
                autocomplete="off"
                class="eze-form-radio"
                name="materialPreference"
                type="radio"
              />
              <span class="ml-1 form-answer">{{ answer.answer }}</span>
            </label>
          </div>
        </div>

        <!-- Which product benefits interest you most -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">
            Which product benefits interest you most?
          </h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.productBenefits != '',
            }"
          >
            {{ errors.productBenefits }}
          </div>
          <div
            v-for="(answer, index) in questions.productBenefits"
            :key="answer.answer"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`productBenefits_${answer.answer}`"
                v-model="quote_request.windowImportance"
                :value="answer.value"
                autocomplete="off"
                class="eze-form-small-checkbox"
                name="productBenefits"
                type="checkbox"
              />
              <span class="ml-1 form-answer">{{ answer.answer }}</span>
            </label>
          </div>
        </div>

        <!-- When are you planning to start your project -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">
            When are you planning to start your project?
          </h4>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.newBuyPlan != '',
            }"
          >
            {{ this.errors.newBuyPlan }}
          </div>

          <div
            v-for="(answer, index) in new_buy_plans"
            :key="index"
            class="mb-2 md:mb-0"
          >
            <label class="inline-flex items-baseline">
              <input
                :id="`planningToStart_${answer.name}`"
                v-model="quote_request.newBuyPlan"
                :value="answer.id"
                autocomplete="off"
                class="eze-form-radio"
                name="planningToStart"
                type="radio"
              />
              <span class="ml-1 form-answer">{{ answer.name }}</span>
            </label>
          </div>
        </div>

        <!-- Are you interested in finance -->
        <div
          id="scrollToStepTwo"
          class="text-sm text-white col-span-3 lg:col-span-1"
          data-name="interestedInFinance"
        >
          <div class="text-sm text-white">
            <h4 class="mb-2 md:mb-0 pr-2 md:pr-10 question-label block">
              Are you interested in finance options?
            </h4>
            <div
              v-for="(question, index) in questions.specialFinancing"
              :key="index"
              class="mb-2 md:mb-0 block"
            >
              <label
                ><input
                  :id="`specialFinancing_${question.answer}`"
                  v-model="quote_request.interested_financing"
                  :value="question.value"
                  autocomplete="off"
                  class="eze-form-radio"
                  name="special_financing"
                  type="radio"
                />
                <span class="ml-1 form-answer">{{
                  question.answer
                }}</span></label
              >
            </div>
          </div>
          <div
            :class="{
              'text-xs text-white italic form-error':
                errors.interested_financing != '',
            }"
          >
            {{ this.errors.interested_financing }}
          </div>
        </div>
      </div>
      <!-- End Questions -->

      <!-- Step 2 -->
      <div
        v-show="showStepTwo"
        id="stepTwo"
        class="eze-quote-request-form mt-6"
      >
        <!-- First name and last name -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <input
              id="firstName"
              v-model="quote_request.firstName"
              class="w-full md:mb-0 md:h-10 h-8 pl-4 focus:outline-none border martin-text-input"
              name="firstName"
              placeholder="First Name"
              type="text"
            />
            <span
              :class="{
                'text-xs text-white italic': errors.firstName !== '',
              }"
            >
              {{ this.errors.firstName }}
            </span>
          </div>
          <div class="w-full mb-3 md:mb-0 ez-input-group">
            <input
              id="lastName"
              v-model="quote_request.lastName"
              class="w-full md:mb-0 md:h-10 h-8 pl-4 focus:outline-none border martin-text-input"
              name="lastName"
              placeholder="Last Name"
              type="text"
            />
            <span
              :class="{
                'text-xs text-white italic': errors.lastName !== '',
              }"
            >
              {{ this.errors.lastName }}
            </span>
          </div>
        </div>
        <!-- Email and phone -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <input
              id="email"
              v-model="quote_request.email"
              class="w-full md:mb-0 md:h-10 h-8 pl-4 focus:outline-none border martin-text-input"
              name="email"
              placeholder="Email"
              type="email"
            />
            <span
              :class="{
                'text-xs text-white italic': errors.email !== '',
              }"
            >
              {{ this.errors.email }}
            </span>
          </div>
          <div class="w-full mb-3 md:mb-0 ez-input-group">
            <input
              id=""
              v-model="quote_request.phone"
              class="w-full md:h-10 h-8 pl-4 focus:outline-none border martin-text-input"
              name=""
              placeholder="Phone Number"
              type="text"
              @input="sanitizePhoneNumber"
            />
            <span
              :class="{
                'text-xs text-white italic': errors.phone !== '',
              }"
            >
              {{ this.errors.phone }}
            </span>
          </div>
        </div>
        <!-- Address and city -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <input
              ref="inputAddress"
              v-model="quote_request.address1"
              class="w-full md:h-10 h-8 pl-4 focus:outline-none border input-address martin-text-input"
              placeholder="Address"
              type="text"
              @change="
                shouldConfirmAddress = true;
                addressConfirmed = false;
              "
            />
            <span
              :class="{
                'text-xs text-white italic': errors.address1 !== '',
              }"
            >
              {{ this.errors.address1 }}
            </span>
          </div>
          <div class="w-full mb-4 md:mb-0 ez-input-group">
            <input
              v-model="quote_request.city"
              class="w-full md:h-10 h-8 pl-4 focus:outline-none border martin-text-input"
              placeholder="City"
              type="text"
            />
            <span
              :class="{ 'text-xs text-white italic': errors.city != '' }"
            >
              {{ this.errors.city }}
            </span>
          </div>
        </div>
        <!-- State and zip -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <select
              id="select-state"
              v-model="quote_request.state"
              class="w-full md:h-10 h-8 pl-4 focus:outline-none border ez-select-input"
              required
              type="text"
            >
              <option selected value="">State</option>
              <option
                v-for="(item, key) in this.states"
                :key="key"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
            <span
              :class="{
                'text-xs text-white italic': errors.state !== '',
              }"
            >
              {{ this.errors.state }}
            </span>
          </div>
          <div class="w-full mb-3 md:mb-0 ez-input-group">
            <input
              v-model="quote_request.zip"
              class="w-full md:h-10 h-8 pl-4 focus:outline-none border martin-text-input"
              maxlength="5"
              placeholder="Zip"
              type="text"
              @input="sanitizeZipCode"
            />
            <span
              :class="{ 'text-xs text-white italic': errors.zip != '' }"
            >
              {{ this.errors.zip }}
            </span>
          </div>
        </div>
        <!-- Additional details -->
        <div class="my-6" data-name="additionalDetails">
          <div class="text-sm ez-input-group">
            <div class="w-full mr-6 mb-3 md:mb-0">
              <input
                v-model="quote_request.additional"
                class="w-full md:mb-0 md:h-10 h-8 pl-4 pr-4 focus:outline-none border additionalDetails martin-text-input"
                max="255"
                name="additional"
                placeholder="Additional details"
                type="text"
              />
            </div>
            <span
              :class="{
                'text-xs text-white italic': errors.additional !== '',
              }"
            >
              {{ this.errors.additional }}
            </span>
          </div>
        </div>
      </div>

      <!-- Get quote button -->
      <div v-show="showStepTwo">
        <span v-if="showLegal" class="l-disclaimer pb-3 pb-md-2"
          >Providing your phone number is consent to receive calls, texts, and
          recorded messages from {{ brandLongName }} and its partners.</span
        >
        <button
          class="w-full text-white text-sm block h-10 leading-10 text-center font-bold bg-martinDarkBlue focus:outline-none get-quote-button-ez"
          @click.prevent="postLead()"
        >
          Get Quote
        </button>
        <div
          v-if="showLegal"
          :data-forcestyle="`ldisclaimer_${brandName}`"
          class="l-disclaimer pt-3 pt-md-2"
        >
          By providing my phone number and clicking on the “Submit” button
          above, I consent, acknowledge, and agree to the following:
          <ul>
            <li>
              {{ brandLongName }}’s
              <a :href="brandPrivacyPolicy" target="_blank">Privacy Policy</a>.
            </li>
            <li>
              That I am providing my express written consent to share my
              information with {{ brandLongName }}’s
              <a
                v-if="brandAuthorizedThirdPartiesUrl"
                :href="brandAuthorizedThirdPartiesUrl"
                target="_blank"
                >partners,</a
              >
              <span v-else>partners,</span>
              and authorized third parties calling on their behalf, to deliver
              calls or text messages to me, including for marketing purposes,
              using an automatic telephone dialing system or an artificial or
              prerecorded voice to the phone number I have provided. I
              understand that I am providing this consent to receive such
              telephone calls even if my telephone number is currently listed on
              any federal, state, local, internal, or corporate Do-Not-Call
              (“DNC”) Lists.
            </li>
            <li>
              I understand that I do not have to agree to receive these types of
              calls or text messages as a condition of purchasing any goods or
              services. I understand that instead of providing consent, I may
              call {{ brandContactPhone }} to speak with an individual customer
              care representative.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--    <modal name="confirm-address-eze" :clickToClose="false" height="auto" :adaptive="true" width="unset" max-width="800px" classes="w-full xl:w-1/2 relative mx-auto rounded-br-15 top-0">-->
    <modal
      :adaptive="true"
      :clickToClose="false"
      classes="modal-override"
      height="auto"
      name="confirm-address-eze"
    >
      <ConfirmAddressFormEze
        :brand_rgba_tuple="brandRgbaTuple"
        :full_wrapper_classes="full_wrapper_classes"
        :quote_request="this.quote_request"
        :url_prepend="url_prepend"
        v-on:address-confirmed="handleAddressConfirmed"
      ></ConfirmAddressFormEze>
    </modal>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import $ from "jquery";
import brandLongName from "../mixins/brandLongName";
import brandLinks from "../mixins/brandLinks";
import ConfirmAddressFormEze from "./ConfirmAddressFormEze.vue";

let gmapAutocompleteInitialized = false;

export default {
  name: "MartinQuoteForm",
  mixins: [brandLongName, brandLinks],
  props: {
    shownOnModal: {
      type: Boolean,
      default: false,
    },
    dealer: "",
    service_types: {},
    home_types: {},
    new_buy_plans: {},
    window_amount: {},
    door_amount: {},
    window_importance: {},
    interested_products: {},
    lead_types: {},
    business_types: {},
    project_conditions: {},
    project_types: {},
    states: {},
    countries: {},
    default_country: "",
    brand: "",
    brandObject: Object,
    source_id: "",
    interested_financing: "",
    redirect_thankyou: "",
    brand_ga: "",
    iframe_domain: "",
    show_close: false,
    data_form: "",
    add_wrapper: false,
    full_wrapper_classes: {
      type: String,
      default: "",
    },
    frontend_url: "",
    client_ip: "",
    user_agent: "",
  },
  data() {
    return this.initData();
  },
  created() {
    // if (this.dealer) {
    // this.initApiToken();
    // }
  },
  mounted() {
    if (this.shownOnModal) {
      // Append dropdown when scrolling to input address element
      let inputAddress = this.$refs.inputAddress;
      $(".v--modal-overlay").scroll(() => {
        let newTop =
          $(inputAddress).offset().top + $(inputAddress).outerHeight();
        $(".pac-container").css("top", newTop + "px");
      });
    }
  },
  components: {
    Loading,
    ConfirmAddressFormEze,
  },

  watch: {
    showStepTwo(newValue, oldValue) {
      if (!this.scrolledToStepTwo && newValue === true && !this.shownOnModal) {
        this.scrolledToStepTwo = true;
        let vm = this;
        this.$nextTick(() => {
          const element = document.querySelector("#scrollToStepTwo");
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        });
      }
    },
  },

  computed: {
    brandName: function () {
      if (this.brandObject.id === 4) return "EZE";
      if (this.brandObject.id === 6) return "Anlin";
      if (this.brandObject.id === 6) return "WWS";
    },
    url_prepend() {
      let val = this.frontend_url ? this.frontend_url : "";
      if (val && val.substring(val.length - 1) === "/") {
        val = val.substring(0, val.length - 1);
      }
      return val;
    },
    isModal() {
      return this.show_close;
    },
    brandRgbaTuple() {
      return "144, 24, 37";
    },

    showStepTwo() {
      if (
        this.quote_request.leadType !== "" &&
        this.quote_request.serviceType !== "" &&
        this.quote_request.doorAmount !== "" &&
        this.quote_request.doorType !== "" &&
        this.quote_request.needDoorOpener !== "" &&
        this.quote_request.windowImportance.length &&
        this.quote_request.newBuyPlan !== "" &&
        this.quote_request.interested_financing !== ""
      ) {
        this.initAddressAutoComplete();
        return true;
      }
    },
    brandAuthorizedThirdPartiesUrl() {
      if (this.brandObject.authorized_third_parties_url) {
        return this.brandObject.authorized_third_parties_url;
      }
      return null;
    },
    shouldShowAddressConfirmationPopup() {
      return (
        this.brandObject?.force_lead_address_validation === 1 &&
        this.shouldConfirmAddress === true &&
        this.addressConfirmed === false
      );
    },
  },
  methods: {
    referralInfo() {
      if (parent !== window) {
        return {
          full_url: document.referrer,
        };
      }
      return {
        full_url: window.location.href,
      };
    },
    sanitizeZipCode() {
      this.quote_request.zip = this.quote_request.zip.replace(/\D/g, "");
    },
    sanitizePhoneNumber() {
      var tmp = this.quote_request.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.quote_request.phone = !tmp[2]
        ? tmp[1]
        : "(" + tmp[1] + ") " + tmp[2] + (tmp[3] ? "-" + tmp[3] : "");
    },
    initGoogleApi(callback) {
      if (typeof google !== "undefined" && google.maps && google.maps.places) {
        callback.apply(this, arguments);
        return;
      }
      let el = document.createElement("script");
      el.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.MIX_GMAP_API_KEY +
        "&libraries=places&language=en";
      document.body.appendChild(el);
      let _t = this;
      return setTimeout(function () {
        callback.apply(this, arguments);
      }, 2000);
    },
    initAddressAutoComplete: function () {
      // if (gmapAutocompleteInitialized) return true;
      // gmapAutocompleteInitialized = true;
      var _t = this;
      this.initGoogleApi(function () {
        var input = _t.$refs.inputAddress;
        var autocomplete = new google.maps.places.Autocomplete(input);
        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();
            if (place && place.address_components) {
              _t.shouldConfirmAddress = false;
              _t.quote_request.confirmation_popup_gmap_suggestion_selected =
                "no";
              var address_components = place.address_components;
              let outStreetNumber = "";
              let outStreetName = "";
              let outCity;
              let outZip = "";
              let outCounty = "";
              let outState = "";
              let outCountry = "";
              for (var i = 0; i < address_components.length; i++) {
                let tComponent = address_components[i];
                let componentType = tComponent.types;
                if (!componentType) return;
                if (componentType.indexOf("street_number") > -1) {
                  outStreetNumber = tComponent.long_name;
                } else if (componentType.indexOf("route") > -1) {
                  outStreetName = tComponent.long_name;
                } else if (componentType.indexOf("locality") > -1) {
                  outCity = tComponent.long_name;
                } else if (componentType.indexOf("postal_code") > -1) {
                  outZip = tComponent.long_name;
                } else if (
                  componentType.indexOf("administrative_area_level_1") > -1
                ) {
                  outState = tComponent.long_name;
                } else if (
                  componentType.indexOf("administrative_area_level_2") > -1
                ) {
                  outCounty = tComponent.long_name;
                } else if (componentType.indexOf("country") > -1) {
                  outCountry = tComponent.long_name;
                }
              }
              _t.quote_request.address1 = outStreetNumber + " " + outStreetName;
              _t.quote_request.city = outCity;
              // Auto filled on select
              _t.quote_request.zip = outZip;
              if (outCountry && _t.countries.indexOf(outCountry) > -1) {
                _t.quote_request.country = outCountry;
              }
              if (outState) {
                _t.quote_request.state = outState;
              }
              if (outCounty) {
                _t.quote_request.county = outCounty;
              }
            }
          }
        );
      });
    },
    postLead() {
      var $this = this;
      if (window.CLabsgbVar !== undefined) {
        $this.cluid = window.CLabsgbVar.generalProps.uid;
      }
      this.checkForm();
      console.log(this.errors);
      if (this.has_errors) {
        return;
      }

      if (this.shouldShowAddressConfirmationPopup) {
        this.quote_request.confirmation_popup_gmap_suggestion_selected = "no";
        this.$modal.show("confirm-address-eze");
        return;
      }

      $this.isLoading = true;

      axios
        .post(this.url_prepend + "/api/lead", $this.quote_request)
        .then((response) => {
          if (response.data.success) {
            $this.$emit("close");

            // Redirect and break;
            setTimeout(function () {
              if ($this.redirect_thankyou) {
                parent.location = $this.redirect_thankyou;
              }
            }, 400);
            if ($this.redirect_thankyou) {
              return;
            }

            // Code below will not execute unless falsy .redirect_thankyou

            setTimeout(() => {
              parent.postMessage({ task: "scroll_top" }, "*");
            }, 50);
            $this.$scrollTo($this.$refs.pageTop);
            $this.isLoading = false;
            window.location = "/thank-you"; // CU-2d8ecpb
            return;
            Swal.fire({
              icon: "success",
              title: "Thank you for your interest in our products.",
              text: "An authorized dealer will contact you shortly.",
              showConfirmButton: false,
              timer: 10000,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              position: "top",
            });

            setTimeout(function () {
              $this.step = 1;
            }, 10000);
            $this.quote_request = {
              serviceType: "",
              newBuyPlan: "",
              homeType: "",
              windowAmount: "",
              doorAmount: "",
              leadType: "H",
              windowImportance: [],
              interestedProducts: ["3"],
              oInterestedProducts: "",
              oWindowAmount: "",
              oWindowImportance: "",
              fullName: "",
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              address1: "",
              city: "",
              state: "",
              zip: "",
              county: "",
              patio_cover: "",
              do_you_have_measurements: "",
              additional: "",
              country: this.default_country,
              dealer_id: $this.dealer ? $this.dealer.id : "",
              bDescription: "",
              bProject: "",
              bProjectType: "",
              bLocation: "",
              bWindowAmount: "",
              bDoorAmount: "",
              bAdditional: "",
              newsletter: null,
              contactMethod: null,
              brand: this.brand,
              source_id: this.source_id,
              interested_financing: this.interested_financing,
              interested_installation: "yes",
              redirect_thankyou: this.redirect_thankyou,
              referral_info: this.referralInfo(),
            };
            $this.errors = $this.validations();
            $this.has_errors = false;
          }
        })
        .catch((error) => {
          $this.$scrollTo($this.$refs.pageTop);
          $this.isLoading = false;
          Swal.fire("Error Processing Request!", "Please try again", "warning");

          if (error.response.status == 401) {
            this.initApiToken();
          }
        });
    },
    formatResult(place) {
      var filter = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        administrative_area_level_2: "county",
        country: "long_name",
        postal_code: "short_name",
      };

      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (filter[addressType]) {
          let val = place.address_components[i][filter[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();
      return returnData;
    },
    getAddressData: function (place) {
      var state = "";
      var city = "";
      var country = "";
      var zip = "";
      var address1 = "";
      var addressData = null;

      if (place.address_components !== undefined) {
        addressData = this.formatResult(place);
      }

      for (var i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types[0] == "locality") {
          city = place.address_components[i];
        }
        if (
          place.address_components[i].types[0] == "administrative_area_level_1"
        ) {
          state = place.address_components[i];
        }
        if (place.address_components[i].types[0] == "country") {
          country = place.address_components[i];
        }
      }

      this.quote_request.city =
        typeof city.long_name !== "undefined" ? city.long_name : "";
      this.quote_request.state =
        typeof state.long_name !== "undefined" ? state.long_name : "";
      this.quote_request.country =
        typeof country.long_name !== "undefined" ? country.long_name : "";
      this.quote_request.zip =
        typeof addressData.postal_code !== "undefined"
          ? addressData.postal_code
          : "";
      this.quote_request.address1 =
        typeof addressData.route !== "undefined" ? addressData.route : "";
    },
    initData() {
      return {
        isLoading: false,
        fullPage: false,
        cluid: "",
        quote_request: {
          serviceType: "",
          newBuyPlan: "",
          homeType: "",
          brand: this.brandObject.id,
          leadType: "H",
          windowImportance: [],
          interestedProducts: ["3"],
          framePreference: "",
          fullName: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          address1: "",
          city: "",
          state: "",
          zip: "",
          county: "",
          // Martin Form Questions
          doorAmount: "",
          doorType: "",
          needDoorOpener: "",
          additional: "",
          // End Martin Form Questions
          country: this.default_country,
          dealer_id: this.dealer ? this.dealer.id : "",
          newsletter: null,
          contactMethod: null,
          source_id: this.source_id,
          interested_financing: "",
          redirect_thankyou: this.redirect_thankyou,
          client_ip: this.client_ip,
          user_agent: this.user_agent,
          referral_info: this.referralInfo(),
          confirmation_popup_gmap_suggestion_selected: "no",
        },
        address: "",
        errors: this.validations(),
        has_errors: false,
        empty_value: false,
        b_description: this.business_types,
        b_project: this.project_conditions,
        b_project_type: this.project_types,
        b_window_amount: "",
        b_door_amount: "",
        b_addional: "",
        hasOInterestedProducts: 0,
        hasOWindowAmount: 0,
        hasOWindowImportance: 0,
        questions: {
          whatBestDescribesYou: [
            { value: "H", answer: "Homeowner" },
            { value: "B", answer: "Industry Professional" },
          ],
          typeOfProject: [
            { value: "H", answer: "New Construction" },
            { value: "B", answer: "Remodel/Replacement" },
          ],
          doorTypes: [
            { value: "single", answer: "Single" },
            { value: "double", answer: "Double" },
            { value: "both", answer: "Both" },
          ],
          needDoorOpener: [
            { value: "yes", answer: "Yes" },
            { value: "no", answer: "No" },
            { value: "not_sure", answer: "Not Sure" },
          ],
          materialPreference: [
            { value: "steel", answer: "Steel" },
            { value: "aluminum", answer: "Aluminum" },
            { value: "copper", answer: "Copper" },
            { value: "composite", answer: "Composite" },
            { value: "not_sure", answer: "Not sure" },
          ],
          productBenefits: [
            { value: "8", answer: "Windows in Garage Door" },
            { value: "9", answer: "Thermal Efficiency" },
            { value: "10", answer: "Noise Reduction" },
            { value: "11", answer: "Minimal Upkeep" },
            { value: "12", answer: "Premium Hardware" },
            { value: "13", answer: "Curb Appeal" },
            { value: "14", answer: "Increasing Home Resale Value" },
          ],
          specialFinancing: [
            { value: "1", answer: "Yes" },
            { value: "0", answer: "No" },
          ],
        },
        step: 1,
        scrolledToStepTwo: false,
        shouldConfirmAddress: false,
        addressConfirmed: false,
      };
    },
    validations() {
      let validations = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address1: "",
        city: "",
        zip: "",
        leadType: "",
        serviceType: "",
        doorAmount: "",
        doorType: "",
        needDoorOpener: "",
        windowImportance: "",
        framePreference: "",
        newBuyPlan: "",
        interested_financing: "",
        additional: "",
      };
      return validations;
    },
    selectSpecialFinancing(value) {
      this.quote_request.interested_financing = value;
    },
    checkForm() {
      this.has_errors = false;
      this.errors = this.validations();

      if (this.quote_request.firstName == "") {
        this.errors.firstName = "First Name is required";
        this.has_errors = true;
      }

      if (this.quote_request.lastName == "") {
        this.errors.lastName = "Last Name is required";
        this.has_errors = true;
      }

      if (!this.quote_request.email) {
        this.errors.email = "Email is required";
        this.has_errors = true;
      } else if (!this.validEmail(this.quote_request.email)) {
        this.errors.email = "Valid email required";
        this.has_errors = true;
      }

      if (!this.quote_request.phone) {
        this.errors.phone = "Phone is required";
        this.has_errors = true;
      } else {
        if (
          this.quote_request.country === "United States" &&
          (this.quote_request.phone.startsWith("(1") ||
            this.quote_request.phone.startsWith("1"))
        ) {
          this.errors.phone = "Phone cannot start with 1.";
          this.has_errors = true;
        }
        if (
          this.quote_request.country === "United States" &&
          (this.quote_request.phone.startsWith("(0") ||
            this.quote_request.phone.startsWith("0"))
        ) {
          this.errors.phone = "Phone cannot start with 0.";
          this.has_errors = true;
        }
        if (this.quote_request.phone.replace(/\D/g, "").length < 10) {
          this.errors.phone = "Enter a valid phone number";
          this.has_errors = true;
        }
      }

      if (["", " ", null].includes(this.quote_request.address1)) {
        this.errors.address1 = "Address is required";
        this.has_errors = true;
      }

      if (this.quote_request.city == "") {
        this.errors.city = "City is required";
        this.has_errors = true;
      }

      if (this.quote_request.zip == "" || this.quote_request.zip.length < 5) {
        this.errors.zip = "Postal Code is required";
        this.has_errors = true;
      }

      // Don't validate State if it's outside the US
      if (this.quote_request.country == this.default_country) {
        if (this.quote_request.state == "") {
          this.errors.state = "State is required";
          this.has_errors = true;
        }
      }

      if (this.quote_request.leadType == "") {
        this.errors.leadType = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.serviceType == "") {
        this.errors.serviceType = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.doorAmount == "") {
        this.errors.interested_installation = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.doorType == "") {
        this.errors.interested_installation = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.needDoorOpener == "") {
        this.errors.interested_installation = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.windowImportance.length < 1) {
        this.errors.interestedProducts = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.newBuyPlan == "") {
        this.errors.newBuyPlan = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.interested_financing == "") {
        this.errors.interested_financing = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.framePreference == "") {
        this.errors.interested_financing = "Please select an option";
        this.has_errors = true;
      }

      if (this.quote_request.additional.length > 255) {
        this.errors.additional =
          "Additional details should be no longer than 255 characters.";
        this.has_errors = true;
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    initApiToken() {
      axios
        .post(this.url_prepend + "/api/token/request", {
          dealer_id: this.dealer.id,
          secret: this.dealer.api_secret
            ? this.dealer.api_secret
            : "dealer-secret",
        })
        .then(
          (response) => {
            if (response.data.success) {
              console.log(response.data.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    handleAddressConfirmed(event) {
      if (event.gmapSuggestionSelected === true) {
        this.quote_request.address1 = event.gmapSuggestedAddress.address1;
        this.quote_request.city = event.gmapSuggestedAddress.city;
        this.quote_request.state = event.gmapSuggestedAddress.state;
        this.quote_request.zip = event.gmapSuggestedAddress.zip;
        this.quote_request.country = event.gmapSuggestedAddress.country;
        this.quote_request.county = event.gmapSuggestedAddress.county;
        this.quote_request.latitude = event.gmapSuggestedAddress.latitude;
        this.quote_request.longitude = event.gmapSuggestedAddress.longitude;
        this.quote_request.confirmation_popup_gmap_suggestion_selected = "yes";
      } else {
        this.quote_request.confirmation_popup_gmap_suggestion_selected = "no";
      }

      this.addressConfirmed = true;
      this.$modal.hide("confirm-address-eze");
      this.postLead();
    },
  },
};
</script>

<style lang="scss">
#select-state:invalid {
  color: #a0adc0;
}

#select-state {
  background-color: white;
}

input.eze-form-radio[type="radio"],
input.eze-form-small-checkbox[type="checkbox"] {
  position: unset !important;
  padding: unset !important;
}

input.eze-form-radio[type="radio"] {
  margin-bottom: 0;
}

.wtb-wrapper {
  input:not([type="file"]):not([type="submit"]):not([type="radio"]),
  select,
  .has-selectlabel label {
    margin: unset !important;
  }

  input.additionalDetails[type="text"] {
    max-width: initial;
  }
}

.pac-container {
  z-index: 10000 !important;
}

[data-forceStyle="ldisclaimer_EZE"] {
  margin-top: 4.5rem !important;
  padding-top: 0 !important;
  @media only screen and (max-width: 991px) {
    margin-top: 4.75rem !important;
  }
}

.l-disclaimer {
  a,
  a:visited {
    text-decoration: underline;
    color: #5ac1a7 !important;
  }
}

.v--modal {
  @apply rounded-br-15;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 80% !important;
  max-width: 900px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: unset !important;
}

.modal-override {
  @apply rounded-br-15;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 80% !important;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: unset !important;
}

.ez-form-wrapper {
  @media only screen and (min-width: 1439px) {
    /* For tablets and up: */
    @apply -mr-16;
  }

  @media only screen and (min-width: 1900px) {
    /* For tablets and up: */
    @apply -mr-36;
  }
}
</style>
